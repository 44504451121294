<template>
  <validation-observer ref="simpleRules">
    <div>
      <loader
        v-if="ShowProduct"
        object="#009bc2"
        color1="#ffffff"
        color2="#1763fd"
        size="5"
        speed="2"
        bg="#343a40"
        objectbg="#999793"
        opacity="80"
        disable-scrolling="false"
        name="circular"
      />
      <!-- <this-header /> -->

      <div class="anan-set-nav-conttainer mt-150">

        <div class="row">
          <div class="col-lg-2 col-md-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>
          <div class="col-md-10 col-lg-10 col-sm-12">
            <h3>{{ $t('editPersonalInfo') }}</h3>
            <b-button
              class="btn btn-gradient-primary mb-1 mr-50 "
              @click="$router.go(-1)"
            >
              <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
            </b-button>
            <div class="dashboard mt-1">
              <div
                class="my-profile"
                data-spm="1"
                data-spm-anchor-id="a2o42.my_profile.0.1.170f392c5LLLNA"
              >
                <div class="my-profile-bd">
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('key-161') }}
                    </h3>
                    <div class="my-profile-item-info">
                      <span
                        v-if="items.profileimg"
                        class="w-50 rounded-2 preview-image-containerv2 mr-2 d-flex justify-content-center"
                      >
                        <img
                          :src="items.profileimg"
                          alt="Preview"
                          class=""
                          @click="showPicture(items.profileimg)"
                        >
                        <div class="preview-image-overlays p-0">
                          <i
                            class="fas fa-times-circle cursor-pointer"
                            @click="deleteImage()"
                          />
                        </div>
                      </span>
                      <b-form-group v-else>
                        <b-form-file
                          id="h-file1"
                          v-model="file_cus"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          class="w-75 rounded-0"
                          @change="fileImage"
                        />
                        <!-- <b-form-input
                          id="h-name"
                          v-model="items.line_id"
                          class="w-75 rounded-0"
                          type="text"
                        /> -->
                      </b-form-group>

                    </div>
                  </div>
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('key-77') }}
                    </h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Full Name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="items.fullname"
                            :disabled="items.fullname"
                            :state="errors.length > 0 ? false : null"
                            class="w-75 rounded-0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      <span>{{ $t('email') }}</span> <span class="gray" /></span></h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="items.email"
                            :disabled="items.email"
                            :state="errors.length > 0 ? false : null"
                            class="w-75 rounded-0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      Line ID
                    </h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <b-form-input
                          id="h-name"
                          v-model="items.line_id"
                          :disabled="items.line_id"
                          class="w-75 rounded-0"
                          type="text"
                        />
                      </b-form-group>
                    </div>
                  </div>
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('mobileNumber') }} <span class="gray" /></span></h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Phone Number"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="items.tel"
                            :state="errors.length > 0 ? false : null"
                            class="w-75 rounded-0"
                            type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <!-- <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      WeChat
                    </h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <b-form-input
                          id="h-name"
                          v-model="wechat"
                          class="w-75 rounded-0"
                          type="number"
                        />
                      </b-form-group>
                    </div>
                  </div> -->

                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      WeChat ID
                    </h3>
                    <div class="my-profile-item-info">
                      <b-form-group>

                        <b-form-input
                          id="h-WeChat"
                          v-model="items.wechat_id"
                          class="w-75 rounded-0"
                          type="text"
                        />

                      </b-form-group>
                    </div>
                  </div>

                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('key-197') }}
                    </h3>

                    <div
                      v-if="items.zipcode"
                      class="my-profile-item-info"
                    >
                      <!-- <div class="grider"> -->
                      <div class="label">
                        {{ $t('address') }}
                      </div>
                      <div class="detail">
                        {{ items.address }} <br>
                        {{ $t('subdistrict') }} {{ items.subdistrict_name }}, {{ $t('district') }} {{ items.district_name }} <br>
                        {{ $t('province') }}{{ items.province_name }} {{ items.zipcode }}
                      </div>
                      <br>

                    </div>
                    <button
                      v-if="!items.zipcode"
                      class="btn btn-gradient-primary"
                      @click="showAddAddr()"
                    >
                      <span>เพิ่มที่อยู่บัตร </span>
                      <!-- <i class="far fa-plus" /> แก้ไขที่อยู่ -->
                    </button>

                  </div>
                  <!-- <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      เลขประจำตัวผู้เสียภาษี
                    </h3>
                    <div class="my-profile-item-info">
                      <b-form-group>
                        <b-form-input
                          id="h-name"
                          v-model="taxIdentification"
                          class="w-75 rounded-0"
                          type="number"
                        />
                      </b-form-group>
                    </div>
                  </div> -->
                  <!-- <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('truckCode') }}
                    </h3>
                    <div class="my-profile-item-info">
                      <span class="gray">{{ items.username }} (EK)</span>
                    </div>
                  </div>
                  <div class="my-profile-item">
                    <h3 class="my-profile-item-title">
                      {{ $t('shipCode') }}
                    </h3>
                    <div class="my-profile-item-info">
                      <span class="gray">{{ items.username }} (SEA)</span>
                    </div>
                  </div> -->

                </div>
                <div class="my-profile-ft">
                  <button
                    class="btn btn-gradient-primary"
                    @click="editData()"
                  >
                    {{ $t('saveData') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="add-addr"
        :title="$t('addNewAddress')"
        :ok-title="$t('save')"
        :cancel-title="$t('cancel')"
        @ok.prevent="addAddr()"
      >
        <validation-observer ref="addRules">

          <b-form-group
            label-for="h-address"
            :label="$t('addressNumber')"
          >
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-textarea
                id="h-address"
                v-model="address"
                row="2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-province-name"
            :label="$t('province')"
          >
            <validation-provider
              #default="{ errors }"
              name="Province"
              rules="required"
            >
              <v-select
                id="h-province-name"
                v-model="selectedProvince"
                label="province_name"
                :options="provinceOptions"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.province_id"
                @input="updateDistricts"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-district-name"
            :label="$t('district')"
          >
            <validation-provider
              #default="{ errors }"
              name="District"
              rules="required"
            >
              <v-select
                id="h-district-name"
                v-model="selectedDistrict"
                label="district_name"
                :options="filteredDistricts"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.district_id"
                @input="updateSubDistricts"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-sub-district-name"
            :label="$t('subdistrict')"
          >
            <validation-provider
              #default="{ errors }"
              name="Sub District"
              rules="required"
            >
              <!-- @click=";(subDistrict = null), (postalCode = null)" -->
              <v-select
                id="h-sub-district-name"
                v-model="selectedSubDistrict"
                label="sub_district_name"
                :options="filteredSubDistricts"
                :state="errors.length > 0 ? false : null"
                :reduce="ele => ele.tumbon_id"
                @input="updatePostalcode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label-for="h-postal-code"
            :label="$t('postalCode')"
          >
            <validation-provider
              #default="{ errors }"
              name="Postal Code"
              rules="required"
            >
              <b-form-input
                id="h-postal-code"
                v-model="postalcode"
                label="zipcode"
                :state="errors.length > 0 ? false : null"
                :disabled="postalcode"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-modal>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </validation-observer>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from './component/SideBar.vue'

export default {
  name: 'CustomerNewView',
  components: {
    // ThisHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    SideBar,
    BFormFile,
    BFormTextarea,
    vSelect,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      email,

      // Edit Data
      fullName: null,
      phoneNumber: null,
      wechat: null,
      lineId: null,
      taxIdentification: null,
      my_email: null,
      items: {
        id: null,
        email: null,
        fullname: null,
        line_id: null,
        tel: null,
        username: null,
      },
      file_cus: null,
      selectedProvince: null,
      selectedDistrict: null,
      selectedSubDistrict: null,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      postalcode: null,
    }
  },
  computed: {
    provinceOptions() {
      return this.itemspvc.map(item => ({
        province_id: item.province_id,
        province_name: item.province_name,
      }))
    },
    filteredDistricts() {
      if (this.selectedProvince) {
        return this.idistrict.filter(ele => (ele.province_id === this.selectedProvince))
          .map(item => ({
            district_id: item.district_id,
            district_name: item.district_name,
            province_id: item.province_id,
          }))
      }
      return this.idistrict.map(item => ({
        district_id: item.district_id,
        district_name: item.district_name,
        province_id: item.province_id,
      }))
    },
    filteredSubDistricts() {
      if (this.selectedDistrict) {
        return this.iSubdistrict.filter(ele => (ele.ampur_id === this.selectedDistrict))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      if (this.selectedProvince) {
        return this.iSubdistrict.filter(ele => (ele.changwat_id === this.selectedProvince))
          .map(item => ({
            tumbon_id: item.tumbon_id,
            sub_district_name: item.sub_district_name,
            ampur_id: item.ampur_id,
            changwat_id: item.changwat_id,
          }))
      }
      return this.iSubdistrict.map(item => ({
        ampur_id: item.ampur_id,
        sub_district_name: item.sub_district_name,
        tumbon_id: item.tumbon_id,
        changwat_id: item.changwat_id,
      }))
    },
    filteredpostalCode() {
      if (this.selectedSubDistrict) {
        return this.izipcode.filter(ele => (ele.tum_id === this.selectedSubDistrict))
          .map(item => ({
            tum_id: item.tum_id,
            zipcode: item.zipcode,
          }))
      }
      return this.izipcode.map(item => ({
        tum_id: item.tum_id,
        zipcode: item.zipcode,
      }))
    },
  },
  async mounted() {
    this.getData()
    await this.getProvince()
    await this.getDistrict()
    await this.getSubdistrict()
    await this.getzipcode()
  },
  methods: {
    async getData() {
      try {
        const { data: res } = await this.$http.get('/Profilecustomer/show')
        this.items = res
      } catch (e) {
        console.log(e)
      }
    },
    editData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const data = {
            fullname: this.items.fullname,
            tel: this.items.tel,
            wechat_id: this.items.wechat_id,
            line_id: this.items.line_id,
            zipcode: this.items.zipcode,
            address: this.items.address,
            subdistrict_name: this.items.subdistrict_name,
            district_name: this.items.district_name,
            district: this.items.district,
            subdistrict: this.items.subdistrict,
            province_name: this.items.province_name,
            province: this.items.province,
            zipcode: this.items.zipcode,
            // taxIdentification: this.taxIdentification,
            email: this.items.email,
            profileimg: this.items.profileimg,
          }
          this.$http.post('/Profilecustomer/update', data)
            .then(() => {
              this.Success('แก้ไขข้อมูลสำเร็จ')
              this.$router.push({ name: 'my-profile' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })

          // this.$router.push({ name: 'my-profile' })
        }
      })
    },
    fileImage(event) {
      if (event) {
        this.file_cus = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.file_cus[0]
        if (!filepath) {
          this.items.profileimg = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.items.profileimg = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }
    },
    async getProvince() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/province') // จังหวัด
        this.itemspvc = res
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/district') // อำเภอ
        this.idistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubdistrict() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/Subdistrict') // ตำบล
        this.iSubdistrict = res
      } catch (e) {
        console.log(e)
      }
    },
    async getzipcode() {
      try {
        const { data: res } = await this.$http.get('/Addresscustomer/zipcode') // ตำบล
        this.izipcode = res
      } catch (e) {
        console.log(e)
      }
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    updateDistricts() {
      this.selectedDistrict = null
      this.selectedSubDistrict = null
    },
    updateSubDistricts() {
      this.selectedSubDistrict = null
      this.postalcode = null
    },
    updatePostalcode() {
      const arr = this.izipcode.find(ele => (ele.tum_id === this.selectedSubDistrict))
      this.postalcode = arr.zipcode
    },
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          if (this.address) {
            this.items.address = this.address
          }
          if (this.selectedProvince) {
            const provi = this.itemspvc.find(ele => (ele.province_id === this.selectedProvince))
            this.items.province_name = provi.province_name
            this.items.province = provi.province
          }
          if (this.selectedDistrict) {
            const arr = this.idistrict.find(ele => (ele.district_id === this.selectedDistrict))
            this.items.district_name = arr.district_name
            this.items.district = arr.district
          }
          if (this.selectedSubDistrict) {
            const arr = this.iSubdistrict.find(ele => (ele.tumbon_id === this.selectedSubDistrict))
            this.items.subdistrict_name = arr.sub_district_name
            this.items.subdistrict = arr.subdistrict
          }
          if (this.postalcode) {
            this.items.zipcode = this.postalcode
          }
          this.$bvModal.hide('add-addr')
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    deleteImage() {
      this.$bvModal.msgBoxConfirm('ต้องการลบรูปโปรไฟล์หรือไม่ ?', {
        title: 'ยืนยันข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          this.items.profileimg = null
          this.file_cus = null
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
